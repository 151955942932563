@font-face {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN_r8OUuhs.ttf) format("truetype");
}
@font-face {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0e.ttf) format("truetype");
}
@font-face {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 600;
	src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UNirkOUuhs.ttf) format("truetype");
}
@font-face {
	font-family: "Open Sans Condensed";
	font-style: normal;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/opensanscondensed/v15/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhDuXMQg.ttf)
		format("truetype");
}
@font-face {
	font-family: "Open Sans Condensed";
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/opensanscondensed/v15/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmDuXMQg.ttf)
		format("truetype");
}
* {
	box-sizing: border-box;
}
.login-container {
	width: 100vw;
	height: 100vh;
	background: rgba(240, 233, 218, 1);
}

.log-form {
	width: 40%;
	min-width: 320px;
	max-width: 475px;
	background: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}
@media (max-width: 40em) {
	.log-form {
		width: 95%;
		position: relative;
		margin: 2.5% auto 0 auto;
		left: 0%;
		top: 0px;
		-webkit-transform: translate(0%, 0%);
		-moz-transform: translate(0%, 0%);
		-o-transform: translate(0%, 0%);
		-ms-transform: translate(0%, 0%);
		transform: translate(0%, 0%);
	}
}
.log-form form {
	display: block;
	width: 100%;
	padding: 2em;
}
.login-logo {
	margin: 10vh auto;
	height: 10vmin;
	pointer-events: none;
}

.log-form h2 {
	color: white;
	/* font-family: "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif"; */
	font-size: 1.35em;
	display: block;
	background: rgba(125, 55, 111, 1);
	width: 100%;
	text-transform: uppercase;
	padding: 0.75em 1em 0.75em 1.5em;
	box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.05);
	border: 1px solid rgba(125, 55, 111, 1);
	margin: 0;
	font-weight: 200;
}
.log-form input {
	display: block;
	margin: auto auto;
	width: 100%;
	margin-bottom: 2em;
	padding: 0.5em 0;
	border: none;
	border-bottom: 1px solid #eaeaea;
	padding-bottom: 1.25em;
	color: #757575;
}
.log-form input:focus {
	outline: none;
}
.log-form .btn {
	display: inline-block;
	background: rgba(125, 55, 111, 1);
	border: 1px solid rgba(125, 55, 111, 1);
	padding: 0.5em 2em;
	color: white;
	margin-right: 0.5em;
	box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.2);
}
.log-form .btn:hover {
	background: rgb(97, 36, 85);
}
.log-form .btn:active {
	background: rgb(97, 36, 85);
	box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.1);
}
.log-form .btn:focus {
	outline: none;
}
.log-form .forgot {
    color: #7D376F;
    font-size: 14px;
    margin: -28px 0 0 0;
    padding: 0;
    float: right;
    font-weight: 600;
}
.log-form .forgot:hover {
	color: #391634;
}
