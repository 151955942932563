.page-default-loader-holder {
	width: 100%;
	height: 100%;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(255, 255, 255, 0.7);
	z-index: 99999;
	top: 0;
	left: 0;
}

.page-default-loader {
	width: 6vmax;
	height: 6vmax;
	/* border-right: 4px solid #7D376F; */
	border-right: 4px solid #000;
	border-radius: 100%;
	-webkit-animation: spinRight 1.5s linear infinite;
	animation: spinRight 1.5s linear infinite;
}
.page-default-loader:before,
.page-default-loader:after {
	content: "";
	width: 4vmax;
	height: 4vmax;
	display: block;
	position: absolute;
	top: calc(50% - 2vmax);
	left: calc(50% - 2vmax);
	/* border-left: 3px solid #E4B23A; */
	border-left: 3px solid #DC143C;
	border-radius: 100%;
	-webkit-animation: spinLeft 1.5s linear infinite;
	animation: spinLeft 1.5s linear infinite;
}
.page-default-loader:after {
	width: 2vmax;
	height: 2vmax;
	top: calc(50% - 1vmax);
	left: calc(50% - 1vmax);
	border: 0;
	border-right: 2px solid #A0A0A0;
	-webkit-animation: none;
	animation: none;
}
@media (max-width: 960px) {
	.page-default-loader {
		width: 8vmax;
		height: 8vmax;
	}
	.page-default-loader:before,
	.page-default-loader:after {
		width: 6vmax;
		height: 6vmax;
		top: calc(50% - 3vmax);
		left: calc(50% - 3vmax);
	}
	.page-default-loader:after {
		width: 4vmax;
		height: 4vmax;
		top: calc(50% - 2vmax);
		left: calc(50% - 2vmax);
	}
}
@-webkit-keyframes spinLeft {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(720deg);
	}
}

@keyframes spinLeft {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(720deg);
	}
}
@-webkit-keyframes spinRight {
	from {
		transform: rotate(360deg);
	}
	to {
		transform: rotate(0deg);
	}
}
@keyframes spinRight {
	from {
		transform: rotate(360deg);
	}
	to {
		transform: rotate(0deg);
	}
}
