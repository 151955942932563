.App {
	text-align: center;
}

.App-logo {
	/* height: 40vmin; */
	pointer-events: none;
}

.App-header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}
label sup {
	color: red;
	font-family: "OpenSansBold";
	font-size: 16px !important;
	margin: 0 0 0 0;
	display: inline-block;
	position: absolute;
	margin-left: 0.2857142857142857rem;
}
a,
button {
	cursor: pointer !important;
}
.PageSearchCategory {
	margin: 0 -34px 0 0 !important;
}
.rmdp-container,
.rmdp-container input {
	width: 100%;
}
.rmdp-container input {
	border: 1px solid #bdbcb9;
	height: 50px;
	padding: 0.75em;
	color: #22252b;
	font-family: "OpenSansSemiBold";
}

.rmdp-time-picker div .rmdp-am {
	justify-content: center;
}
.rmdp-arrow-container {
	border-radius: 5px !important;
	width: 24px !important;
	background: #7d376f !important;
	color: #fff !important;
	align-items: center !important;
}
.rmdp-arrow-container:hover {
	background: #4e1e45 !important;
}
.rmdp-arrow {
	border-color: #ffffff !important;
}
.rmdp-up i {
	margin-top: 1px !important;
}
.rmdp-down i {
	margin-top: -3px !important;
}
.rmdp-left i,
.rmdp-right i {
	margin-top: 0;
}
.rmdp-time-picker div input {
	color: #22252b;
	font-family: "OpenSansSemiBold";
	outline: none !important;
	font-size: 16px !important;
	width: 30px !important;
}
.rmdp-only-time-picker {
	width: 240px;
	padding: 15px 0;
}
.rmdp-time-picker div .rmdp-am {
	color: #22252b;
	font-family: "OpenSansSemiBold";
	font-size: 16px !important;
}
.rmdp-day.rmdp-selected span:not(.highlight) {
	background-color: #7d376f !important;
	box-shadow: 0 0 3px #7d376f !important;
}
.rmdp-panel-body {
	padding: 0 5px !important;
}
.rmdp-panel-body li {
	background-color: #7d376f !important;
	color: white !important;
	margin: 4px 1px !important;
}
.rmdp-week-day {
	color: #41453f !important;
}
.css-1vmnjpn-skeletonStyles-Skeleton {
	width: 100% !important;
}
