body {
	margin: 0;
	font-family: "OpenSansRegular";

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: "OpenSansRegular";
}
.badge {
	background: linear-gradient(135deg, #fec163 0%, #dea513 100%);
	border-radius: 4px;
	width: 38px;
	height: 14px;
	font-size: 9.8459px;
	font-family: "OpenSansBold";
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 7px !important;
}
.badge-count {
	border-radius: 50px;
	width: 24px;
	height: 24px !important;
	background: #eb5757;
	border: 2px solid #fff;
	font-size: 14px;
	line-height: 18px;
	font-family: "OpenSansSemiBold";
	padding: 0 !important;
	display: flex;
	align-items: CENTER;
	justify-content: center;
}

.Toastify {
	width: 100%;
	position: fixed;
	bottom: 0;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 0 10px 10px;
	z-index: 1000000;
}
.Toastify .Toastify__toast-container {
	position: relative;
	right: auto;
	bottom: 0;
}

.Toastify .Toastify__toast-container .Toastify__toast {
	background: #fff;
	border-radius: 5px;
	text-align: center;
	color: #000;
}
.Toastify .Toastify__progress-bar {
	/* background: hsla(0, 0%, 100%, 0.5); */
	background: #64A2F5;
}
.sweet-alert {
	padding: 0 !important;
	width: 30rem;
}

.sweet-alert h2 {
	font-size: 22px !important;
	line-height: 23px;
	font-family: "OpenSansBold";
	color: #0f255f;
	padding-top: 55px !important;
	font-weight: normal !important;
	margin-bottom: 32px !important;
}

.sweet-alert form {
	color: #53627c;
	font-size: 16px;
	line-height: 20px;
	font-family: "OpenSansMedium";
	margin-top: 0;
	border: none;
	padding: 0 20px;
}
.sweet-alert form br {
	display: none;
}
.sweet-alert p {
	border-top: 0;
	padding: 42px 0 45px !important;
	margin-top: 0 !important;
}

.sweet-alert p a {
	min-width: 100px;
	height: 40px;
	display: flex;
	box-shadow: none !important;
	background: #ffffff;
	/* border: 1px solid #7d376f !important; */
	border: 1px solid #000 !important;
	box-sizing: border-box;
	border-radius: 8px;
	font-size: 16px;
	line-height: 23px;
	font-family: "OpenSansSemiBold";
	color: #41453f;
	text-decoration: none;
	align-items: center;
	justify-content: center;
}

.sweet-alert p a:hover {
	/* background: #7d376f; */
	background: #000;
	color: #fff;
}
.sweet-alert p a + a {
	/* background: #7d376f; */
	background: #000;
	color: #fff;
	border: none;
}

@media (max-width: 768px) {
	.slick-slider {
		margin-bottom: 26px;
	}
}
h1,
h2,
h3,
h4,
h5,
h6,
strong {
	font-weight: normal;
}
strong {
	font-family: "OpenSansBold";
}
.tooltip-body {
	flex-direction: column;
	padding: 0 0 7px 0;
}
.tooltip-body span {
	display: flex;
	align-items: center;
	padding: 8px 0px 0;
}
.tooltip-body span span {
	padding: 0;
}
.tooltip-body span div {
	margin-left: 7px;
	margin-right: 12px;
	font-size: 13px;
	text-align: left;
	line-height: normal;
}
.tooltip-body span svg {
	width: 35px;
	height: 35px;
	margin-right: 5px;
}
.tooltip-content label {
	width: 100%;
	text-align: left;
	display: flex;
	padding: 0 10px;
}
.tooltip-content label strong {
	margin-left: auto;
	padding-left: 20px !important;
}
.tippy-tooltip {
	background-color: #ffffff !important;
	box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.10) !important;
	border-radius: 6px !important;
	padding: 6px 10px !important;
	color: #434242 !important;
	font-family: "OpenSansMedium" !important;
	font-size: 14px !important;
}
.tippy-popper[x-placement^="top"] [x-arrow] {
	border-top: 7px solid #ffffff !important;
}
.tippy-popper[x-placement^="bottom"] [x-arrow] {
	border-bottom: 7px solid #fff!important;
}
.tooltip-body span div {
	font-size: 14px !important;
}
.tippy-popper[x-placement^="right"] [x-arrow] {
	border-right: 7px solid #ffffff !important;
}
.tippy-popper[x-placement^="left"] [x-arrow] {
	border-left: 7px solid #ffffff !important;
}
.simplebar-track.simplebar-vertical {
	width: 8px;
}
.simplebar-scrollbar:before {
	background: #7d376f;
}
.simplebar-scrollbar.simplebar-visible:before {
	opacity: 1 !important;
	background: #7d376f;
}
Input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	display: none;
}
button:disabled {
	pointer-events: none;
	opacity: 0.7;
}
.sweet-alert form hr {
	display: none;
}
.tippy-popper{
	z-index: 999999!important;
} 
.tippp-popper.no-stock{
    background-color: #EB5757!important;
    color: #fff!important;
}
.tippy-popper.no-stock[x-placement^="top"] [x-arrow]{
	border-top:7px solid #EB5757!important;
}

.out-of-stock{
	font-size: 15px;
}
@media (max-width: 768px) {
	.tippy-popper{
		display: none;
	}

}
body.sweetalert-overflow-hidden {
    overflow: auto!important;
}